/**
 * --------------------------------------------------------------------------
 * Marqueefy (v1.0.2): index.umd.js
 * Licensed under MIT (https://github.com/marqueefy/marqueefy.github.io/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

import Marqueefy from './src/marqueefy'

export default { Marqueefy }
